$breakpoint-desktop: 1024px;

.flex-row {
    flex-direction: column!important;
    justify-content: center;
}

.Features {
    margin-left: 5%;
    margin-right: 5%;
}

@media (min-width: $breakpoint-desktop) {
    .flex-row {
        flex-direction: row;
    }
}
