
a {
    color: #fff!important;
    text-decoration: none;
}

.nav-item {
    font-size: 1.5rem;
    align-self:center;
}

#text {
    display: none;
}

#logo {
    width: 50px;
}


.navbar-toggler-icon {
    //invert the color
    filter: invert(1);
}

.navbar-text {
    font-size: 1.2rem;
    align-self: start;
    color: white;
}


.nav-button {
    font-size: 1.2rem;
    width: 100%!important;
    margin-top: 1rem;
}

.nav-button-container {
    margin-right: 0;
}

.navbar-nav {
    padding-left: 5vw;
    padding-right: 5vw;
}

.Navbar {
    padding-top: 1rem;
    margin-bottom: 3vh;
}

$breakpoint-fold: 353px;
@media (max-width: $breakpoint-fold) {
   .navbar-text {
       display: none;
   }
}


$breakpoint-desktop: 992px;
@media (min-width: $breakpoint-desktop) {

    .navbar-brand-container {
        width: 50%;
    }

    .Navbar {
        padding-left: 1rem;
    }

    .navbar-text {
        font-size: 1.2rem;
    }

    .nav-link {
        margin-left: 0;
    }

    #text {
        display: block!important;
    }

    .nav-item {
        font-size: 1.2rem;
        margin-right: 1.5em;
        align-self: center;
    }

    .nav-button {
        font-size: 1.2rem;
        margin-top: 0;
        border-radius: 1.8rem!important;
    }
    
    .navbar-nav {
        padding-left: 0;
        padding-right: 0;
    }
}