$primaryColor: #07397A;
$secondaryColor: #187bcd;
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

body{
  font-size: 62.5%;
  background-color: white;
}

.navbar-toggler {
  background-color: $secondaryColor;
  margin-right: 3vw;
}


.App {
  font-family: 'Open Sans', sans-serif;
}

.bg-primary {
  background-color: $primaryColor!important;
  color: white;
}

.bg-secondary {
  background-color: $secondaryColor!important;
  color:white;
}

.btn-custom {
  background-color: $secondaryColor;
  color: white;
  text-align: center;
  padding: 0.5rem 1.5rem;
  border-radius: 1.8rem;
}

.btn-custom:hover {
  background-color: darken($secondaryColor, 10%);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
