$primaryColor: #07397A;

.Header-Container {
    background-color: $primaryColor;
    border-radius: 0 0 50% 50%;

}

.Header {
    background-color: $primaryColor;
    color: white;
    flex-direction: column-reverse;
    text-align: start;
}

#cta-header {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    text-align: start;
    font-size: 2.7rem;
}

strong {
    font-weight: 700!important;
}

article {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1rem;
    text-align: start;
}

.Display {
    height: fit-content;
    width: 100%;
    height: 100%;
}


.header-col {
    padding: 0 10vw;
    margin: 2rem;
}

#wave {
    position: absolute;
    align-self: last baseline;
    z-index: 0;
    left:0;
    right:0;
    margin-bottom: -10px;
}

@supports (-webkit-overflow-scrolling: touch) {
    #wave {
        bottom:0;
    }
}

$little-phone: 290px;
@media(max-width: $little-phone) {
    #cta-header {
        font-size: 2rem;
    }

    .Display {
        padding: 0 5vw;
    }
}

$big-phone: 300px;
@media(min-width: $big-phone) {

    article {
        font-size: 1.1rem;
    }

    .btn {
        font-size: 1.2rem;
    }

    .Display {
        margin-top: 7vh;
        padding: 0 10vw;

    }

    #cta-header {
        font-size: 2rem;
    }
    
}


$breakpoint-fold: 700px;
@media (min-width: $breakpoint-fold) {
    #cta-header {
        font-size: 2.5rem;
    }

    .Display {
        padding: 0 5vw;
    }
}

$breakpoint-tablet-small: 768px;

@media (min-width: $breakpoint-tablet-small) {
    .Display {
        padding: 0 0vw;
    }
}

$breakpoint-tablet: 992px;
@media (min-width: $breakpoint-tablet) {
   
    .Header {
        flex-direction: row;
        height: 75vh;
    }

    #wave {
        top: 65vh;
        width: 100vw;

    }

    #cta-header {
        font-size: 2.5vw;
    }

    article {
        font-size: 1.2vw;
        padding-left: 15vw;
        padding-right: 15vw;
    }

    h5 {
        font-size: 1.5rem!important;
    }


    .header-col {
        align-self: center;
        padding: 0 2vw;
    }

    .Display {
        margin-top: 0;
    }

    
}