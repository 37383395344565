$primaryColor: #07397A;

.Testimonial {
    article {
        font-style: italic;
        font-size: 1rem!important;
    }
}

.testimonial-text {
    background-color: #07397A;
    color: white;
}

.testimonial-image {
    object-fit: cover;
}

.testimonial-section {
    height: 55vh;
}

$breakpoint-fold: 500px;
@media (max-width: $breakpoint-fold) {
    .testimonial-section {
        height: 55vh;
    }

    .testimonial-img {
        height: 50%!important;
    }

    .Testimonial {
        article {
            font-style: italic;
            font-size: 0.8rem!important;
        }
    }
}

$breakpoint-tablet: 768px;
@media (min-width: $breakpoint-tablet) {
   

    .Testimonial {
        article {
            font-style: italic;
            font-size: 1.6rem!important;
        }
    }
}

$breakpoint-desktop: 1024px;
@media (min-width: $breakpoint-desktop) {
    

    .Testimonial {
        article {
            font-style: italic;
            font-size: 1.4rem!important;
        }
    }
}