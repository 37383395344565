.Footer {
    article {
        font-size: 1rem;
    }
}

.footer-title {
    font-size: 2.1rem;
    font-weight: 600;
    margin: 1rem 0;
}