$primaryColor: #07397a;

.seperator-line {
    padding-left: 47%;
    padding-right: 47%;

    hr {
        border-top: 3px solid $primaryColor;
        opacity: 1;
    }

    .name {
        font-weight: 600!important;
    }
}


.Seperator {
    background-color: white;
    padding-top: 5vh!important;
    z-index: 1;
    border-top-right-radius: 10%;
}
