$primaryColor: #07397A;

article {
    padding: 0;
}

.icon-container {
    border-radius: 50%;
    height: 100%;
    background-color: $primaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;

    i {
        color: white;
        font-size: 2.4rem;
    }
}

.Feature {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.feature-header {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
}

$breakpoint-desktop: 1200px;
@media (min-width: $breakpoint-desktop) {
    .Feature {
     
    }

}